.sections {
    margin-top: 2%;
}

.bgimg {
    width: 100%;
    height: 90vh;
    position: absolute;
}

.pogramhigh {
    margin-top: 100px;
}

.navbaroverimage {
    position: relative;
    margin-left: 90px;
}

.besantNavbar {
    margin-left: 20px;
}

.brandName {
    font-weight: 600;
    margin-left: 70px;
    font-size: 20px;
    font-family: "Alata";
}

.linking {
    display: block;
    justify-content: start;
}

.sublinking {
    font-size: 16px;
    color: white;
    font-weight: 400;
    font-family: "Alata";
    letter-spacing: 1px;
}

.mainlinking {
    display: flex;
    flex-direction: row;
}

.enrollnow {
    border-color: #e97b2d;
    background-color: #e97b2d;
    color: white;
    font-size: 15px;
    height: 40px;
    width: auto;
    border-radius: 5px;
    font-family: "Alata";
}

.mainheading {
    color: white;
    font-weight: 800;
    letter-spacing: 0.5px;
    font-size: 38px;
    font-family: "Alata";
}

.vl {
    border-left:3px solid #ff8c18 ;
    height: 30px;
    margin-top: 50px;
}

.subheading1 {
    margin-left: 10px;
    color: white;
    font-weight: 400;
    letter-spacing: 0.2px;
    font-family: "Alata";
    /* line-height: 30px; */
    font-size: 22px;
}

.submainheading {
    margin-top: 20px;
}

.subheading2 {
    letter-spacing: 0.5px;
    color: white;
    line-height: 30px;
    font-weight: 400;
    font-family: "Alata";
}

.subheading3 {
    letter-spacing: 0.5px;
    color: white;
    line-height: 22px;
    font-weight: 500;
    margin-left: 10px;
    font-family: "Alata";
}

.subheading3-1 {
    letter-spacing: 0.5px;
    color: white;
    line-height: 22px;
    font-weight: 500;
    font-family: "Alata";
}

.submainheading1 {
    margin-top: 20px;
}

.tickicons {
    color: white;
    width: 20px;
    height: 20px;
    margin-top: 0px;
}

.download {
    color: white;
    font-size: 16px;
    height: 80px;
    width: 100%;
    border-radius: 5px;
    border-color: #1FB000;
    background-color: #1FB000;
    font-weight: 500;
    font-family: "Alata";
}

.available {
    color: white;
    font-size: 16px;
    margin-top: 15px;
    font-family: "Alata";
}

.courseSyllabus {
    font-family: "Alata";
}

.onlineclasess {
    color: yellow;
    font-family: "Alata";
}

.cardheader {
    background-color: #398dec;
}

.arrowicon {
    display: flex;
    justify-content: end;
    align-items: center;
}

.maincard {
    width: 60vh;
    box-shadow: rgba(0, 0, 0, 0.25) 0px -10px 15px, rgba(0, 0, 0, 0.12) 0px -20px 30px, rgba(0, 0, 0, 0.12) 0px 20px 50px, rgba(0, 0, 0, 0.17) 0px 1px 13px, rgba(0, 0, 0, 0.09) 0px 10px 5px;
}

.events li {
    display: flex;
    color: black;
    font-size: 12px;
    font-family: "Alata";
}

.events time {
    position: relative;
    /* padding: 0 1.5em; */
}

.events time::after {
    content: "";
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    transform: translateX(50%);
    border-radius: 50%;
    background: rgb(64, 165, 212);
    border: 1px #ccc solid;
    width: .8em;
    height: .8em;
}


.events span {
    padding: 0 1.5em 1.5em 1.5em;
    position: relative;
}

.events span::before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    height: 100%;
    border-left: 1px #ccc solid;
}

.events strong {
    display: block;
    font-weight: bolder;
    font-family: "Alata";
}

.events {
    margin: 1em;
    /* width: 50%; */
}

.events,
.events *::before,
.events *::after {
    box-sizing: border-box;
    font-family: "Alata";
}

.subtitile {
    font-size: 16px;
    font-family: "Alata";
    color: grey;
    margin-top: 20px;
}



/*Left Side Buttons Start*/
.sticky-left-container {
    padding: 0px;
    margin: 0px;
    position: fixed;
    left: -129px;
    top: 40%;
    width: 200px;
    z-index: 2;
}

.sticky-left li {
    list-style-type: none;
    background-color: none;
    color: #efefef;
    height: 43px;
    padding: 0px;
    margin: 0px 0px 1px 0px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
}

.sticky-left li:hover {
    margin-right: -150px;
    background: #333;
    border-radius: 25px 25px 25px 0;
}

.sticky-left li img {
    float: right;
    border-radius: 50%;
    margin: 5px 5px;
    margin-left: 10px;
}

.sticky-left li p {
    font-family: "Alata";
    padding: 0px;
    float: right;
    margin: 0px;
    text-transform: uppercase;
    line-height: 43px;
}

.sticky-container {
    padding: 0px;
    margin: 0px;
    position: fixed;
    right: -120px;
    top: 20%;
    width: 200px;
    z-index: 2;
}


.sticky li {
    list-style-type: none;
    background-color: #333333;
    color: #efefef;
    height: 43px;
    padding: 0px;
    margin: 0px 0px 1px 0px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%);
}

.sticky li:hover {
    margin-left: -115px;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(0%);
}

.sticky li img {
    float: left;
    margin: 5px 5px;
    margin-right: 10px;
}

.sticky li p {
    float: left;
    padding: 0px;
    margin: 0px;
    text-transform: uppercase;
    line-height: 43px;

}

.cardWithRightImg {
    width: 75%;
    height: 70%;

}

.cardSection1Img {
    background-color: #0BACF5;
    margin: 20px 0px 20px 0px;

}



.myfooter {
    position: fixed;
    width: 100%;
    bottom: 0;
    box-sizing: border-box;
    z-index: 9999;
    background: -webkit-linear-gradient(top, #f5b152 0%, #ff8c18 100%);
    color: white;
    font-size: 10px;
    font-weight: 500;
    height: auto;
    cursor: pointer;
    display: flex;
    align-items: center !important;
    font-family: "Alata";
    justify-content: center;
}

h2.contact {
    font-size: 18px;
}

main {
    text-align: center;
    padding: 14px 0 20px 14px;
}

button {
    padding: 7px 10px;
    margin: 0 0 8px 0;
}

@media (max-width: 480px) {
    body {
        font-size: 14px;
    }
    
}

/*Left Side Buttons End*/
/*Content End*/
@media (max-width: 1599px) {
    .vl {
        margin-top: 25px;
        height: 0;
    }

    .subheading1 {
        margin-left: -5px;
        color: white;
        font-weight: 400;
        letter-spacing: 0px;
        font-family: "Alata";
        line-height: 30px;
        font-size: 22px;
        margin-bottom: 30px;
    }

    .submainheading {
        margin-top: 100px;
    }
}

@media (max-width: 550px) {
  
    .bgimg {
        width: 100%;
        height: 180vh;
        position: absolute;
    }

    .besantNavbar {
        margin-left: 0px;
    }

    .brandName {
        margin-left: 0px;
    }

    .mainlinking {
        display: block;
    }

    .enrollbtn {
        display: flex;
        justify-content: center;
    }

    .navbaroverimage {
        margin-left: 0px;
    }

    .vl {
        margin-top: 25px;
        height: 0;

    }

    .submainheading {
        margin-top: 100px;
    }

    .available,
    .onlineclasess {
        color: black;
        font-size: 18px;
        font-family: "Alata";
    }

    .download {
        font-size: 18px;
        width: 100%;

    }

    .downloadbtn {
        display: flex;
        justify-content: center;
    }

    .maincard {
        width: 100%;
    }
    .myfooter {
        position: fixed;
        width: 100%;
        bottom: 0;
        box-sizing: border-box;
        z-index: 9999;
        background: -webkit-linear-gradient(top, #f5b152 0%, #ff8c18 100%);
        color: white;
        font-size: 10px;
        font-weight: 500;
        height: auto;
        cursor: pointer;
        display: flex;
        align-items: center !important;
        font-family: "Alata";
        justify-content: center;
        text-align: center;
    }
    main {
        text-align: center;
        padding: 40px 0 20px 14px;
    }
}

/* Image from https://www.behance.net/gallery/76726697/ColorLimitedISO */

.bg {
    animation: slide 3s ease-in-out infinite alternate;
    /* background-image: linear-gradient(-60deg, #009139  0%, #0BACF5 100%); */
    background-image: linear-gradient(-60deg, #009139 50%, #0BACF5 50%);
    bottom: 0;
    left: -50%;
    opacity: .5;
    position: fixed;
    right: -50%;
    top: 0;
    z-index: -1;
}

.bg2 {
    animation-direction: alternate-reverse;
    animation-duration: 4s;
}

.bg3 {
    animation-duration: 5s;
}

@keyframes slide {
    0% {
        transform: translateX(-25%);
    }

    100% {
        transform: translateX(25%);
    }
}



#ContactForm1{
    display:none;
    }
    #contact_wrap {
    margin: auto;
    width: 100%;
    height:500px;
    padding: 25px;
    border-radius: 1em;
    border-top:1px solid #60d0f8;
    border-right:1px solid #60d0f8;
    border-left:1px solid #60d0f8;
    border-bottom:1px solid #60d0f8;
    background-color:#fff5f5;
    filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr='#f2f2f2', endColorstr='#cccccc');
    background-image:-webkit-linear-gradient(top, #f2f2f2 0%, #e6e6e6 50%, #cccccc 100%);
    background-image:-moz-linear-gradient(top, #f2f2f2 0%, #e6e6e6 50%, #cccccc 100%);
    background-image:-ms-linear-gradient(top, #f2f2f2 0%, #e6e6e6 50%, #cccccc 100%);
    background-image:-o-linear-gradient(top, #f2f2f2 0%, #e6e6e6 50%, #cccccc 100%);
    background-image:linear-gradient(top, #f2f2f2 0%, #e6e6e6 50%, #cccccc 100%);
    box-shadow: 1px 1px 5px #ccc;
    }
    #contact_wrap h3{
    color: #e8f3f9;
    font-family:Georgia;
    font-size: 20px;
    font-style:italic;
    font-weight:bold;
    margin: 0 -36px 20px -36px;
    padding: 12px;
    text-align: center;
    text-shadow: 2px 0 0 #1f4962;
    -webkit-box-shadow: inset 0 0 25px rgba(0,0,0,0.3),0px 1px 5px #666;
    -moz-box-shadow: inset 0 0 25px rgba(0,0,0,0.3),0px 1px 5px #666;
    box-shadow: inset 0 0 25px rgba(0,0,0,0.3),0px 1px 5px #666;
    background-color: #308abe;
    position: relative;
    }
    #contact_wrap h3:before {
    content: ' ';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 0 10px;
    border-color: #333 transparent transparent transparent;
    }
    #contact_wrap h3:after {
    content: ' ';
    position: absolute;
    bottom: -10px;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 10px 10px;
    border-color: transparent transparent transparent #333;
    }
    #ContactForm1_contact-form-name{
    width: 100%; 
    height:auto;
    margin: 5px auto; 
    padding: 10px 10px 10px 40px;
    background:#deefff url(http://2.bp.blogspot.com/-GoNmkIDybR0/UZuZDpVOXaI/AAAAAAAAEO8/pNPaQU1aiwQ/s1600/user.png)no-repeat 10px center; 
    color:#777;
    border:1px solid #ccc;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.247059) 0px 1px 3px inset, #f5f5f5 0px 1px 0px;
    box-shadow: rgba(0, 0, 0, 0.247059) 0px 1px 3px inset, #f5f5f5 0px 1px 0px;
    }
    #ContactForm1_contact-form-phone{
           width: 100%; 
    height:auto;
    margin: 5px auto; 
    padding: 10px 10px 10px 40px;
    background:#deefff url(http://2.bp.blogspot.com/-GoNmkIDybR0/UZuZDpVOXaI/AAAAAAAAEO8/pNPaQU1aiwQ/s1600/user.png)no-repeat 10px center; 
    color:#777;
    border:1px solid #ccc;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.247059) 0px 1px 3px inset, #f5f5f5 0px 1px 0px;
    box-shadow: rgba(0, 0, 0, 0.247059) 0px 1px 3px inset, #f5f5f5 0px 1px 0px;
    }
    #ContactForm1_contact-form-email{
    width: 100%; 
    height:auto;
    margin: 5px auto; 
    padding: 10px 10px 10px 40px;
    background: #deefff url(http://3.bp.blogspot.com/-zGxMJ_C5R60/UZuZGnKgcuI/AAAAAAAAEPE/KwPOHFuBffc/s1600/pen.png)no-repeat 10px center; 
    color:#777;
    border:1px solid #ccc;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.247059) 0px 1px 3px inset, #f5f5f5 0px 1px 0px;
    box-shadow: rgba(0, 0, 0, 0.247059) 0px 1px 3px inset, #f5f5f5 0px 1px 0px;
    }
    #ContactForm1_contact-form-email-message{
    width: 100%; 
    margin: 5px auto; 
    padding: 10px 10px 10px 40px;
    font-family:Arial, sans-serif;
    background: #deefff url(http://1.bp.blogspot.com/-QduNKpNbFyQ/UZuZPuE_OfI/AAAAAAAAEPU/Rv4C4Kic2I0/s1600/msg2.png)no-repeat 10px 10px; 
    color:#777;
    border:1px solid #ccc;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.247059) 0px 1px 3px inset, #f5f5f5 0px 1px 0px;
    box-shadow: rgba(0, 0, 0, 0.247059) 0px 1px 3px inset, #f5f5f5 0px 1px 0px;
    }
    #ContactForm1_contact-form-submit {
    width: 95px; 
    height: 30px; 
    float:left;
    /* float: center;  */
    color: #FFF;
    padding: 0; 
    cursor:pointer;
    margin: 25px 0 3px 0 0;
    background-color:#005a8a;
    border-radius:4px;
    text-shadow: 1px 0 0 #1f4962;
    -webkit-box-shadow: inset 0 0 35px rgba(0,0,0,0.3),0px 1px 5px #666;
    -moz-box-shadow: inset 0 0 35px rgba(0,0,0,0.3),0px 1px 5px #666;
    box-shadow: inset 0 0 35px rgba(0,0,0,0.3),0px 1px 5px #666;
    background-color: #3689b9;
    border:1px solid #194f6d;
    }
    #ContactForm1_contact-form-submit:hover {
    background:#4c9bc9;
    }
    #ContactForm1_contact-form-error-message, #ContactForm1_contact-form-success-message{
    width: 320px;
    margin-top:35px;
    }