.trainingwholeMain{
    background-color: rgba(236, 236, 236, 0.855);
    height: auto;
}
.trainingMainheading1 {
    font-size: 30px;
    font-weight: 600;
    display: flex;
    justify-content: center;
   font-family:"Alata";
    color:#222
}

.subcard {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(241, 241, 241) 0px 0px 0px 3px;
}

.mainCardWithContent {
    margin-top: 4%;
}

@media (max-width: 550px) {
    .trainingMainheading1 {
        display: flex;
        justify-content: center !important;
        font-size: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
       font-family:"Alata";
    }

    .subcard {
        margin-bottom: 30px;
    }
}