.trusted {
  margin-top: 60px;
  background-color:white;
  width: 100%;
  height: auto;
  /* margin-left: 20px; */
}

.mainCarousel {
  padding: 50px
}

.footerCardTitle {
  margin-bottom: 30px;
}
.review5{
margin-left: 70px;
}
.carouselCard {
  height: 25rem;
}

.trainingheading1 {
  display: flex;
  justify-content: center;
  font-size: 28px;
  font-weight: 700;
  margin-top: 50px;
  font-family:"Alata";
}

.card.mainTestimonial {
  border: 2px solid black;
  height: 35vh;
}

.stars {
  color: orange;
  font-size: 18px;
}

.years {
  font-size: 12px;
}

.testPara {
  color: black;
  font-family:"Alata";
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 550px) {
  .trainingheading1 {
    display: flex;
    justify-content: center !important;
    font-size: 28px;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family:"Alata";
  }

  .trusted {
    height: auto;
  }

  .card.mainTestimonial {
    border: 2px solid black;
    /* height: 75vh; */
  }
  .itemContent{
    width: 100%;
  }
  .photosWithReview{
    width: 100%;
  }
}


body {
  font-family:"Alata";
}

:root {
  --mon: "Alata";
  --blue: #3968cb;
  --darkblue: #141461;
}
.testimonial-area {
  background: var(--blue);
  position: relative;
  z-index: 2;
  padding: 50px 0;
  border-radius: 20px;
  height: 50vh;
}

.single-testimonial {
  border: 7px solid #fff;
  text-align: center;
  border-radius: 45px;
  position: relative;
  z-index: 2;
  height: 37vh;
}

.single-testimonial p {
  color: #fff;
  font-size: 15px;
  line-height: 24px;
  padding-bottom: 40px;
  position: relative;
  z-index: 3;
  font-family:"Alata";
  padding: 10px;
}

.single-testimonial::before {
  content: "";
  position: absolute;
  left: -19px;
  top: -45px;
  background: url(https://i.ibb.co/nb8Hjms/quote.png) no-repeat var(--blue);
  background-size: 60%;
  width: 126px;
  height: 100px;
  transform: rotate(180deg);
  background-position: 34px 15px;
  border-radius: 20px;
}

.single-testimonial::after {
  content: "";
  position: absolute;
  right: -19px;
  bottom: -34px;
  background: url(https://i.ibb.co/nb8Hjms/quote.png) no-repeat var(--blue);
  background-size: 60%;
  width: 126px;
  height: 100px;
  background-position: 34px 19px;
  border-radius: 20px;
}

.cardTitleTest {
  color: black;
  font-size: 19px;
  font-weight: 700;
  font-family:"Alata";
  margin-top: 20px;
  
}
.carouselMain{
  margin-top: 70px;
}

@media (max-width: 550px) {
  .single-testimonial {
    border: 7px solid #fff;
    text-align: center;
    border-radius: 45px;
    position: relative;
    z-index: 2;
    height: 45vh;
  }

  .testimonial-area {
    background: var(--blue);
    position: relative;
    z-index: 2;
    padding: 50px 0;
    border-radius: 20px;
    height: 60vh;
  }

}