.frequently {
  background-color: rgb(251, 243, 245);
  height: auto;

}

.accordionMain {
  margin-top: 1%;
}

ul {
  font-family:"Alata";
  font-size: 13px;
  line-height: 1.5em;
  margin: 5px 0 15px;
  padding: 0;

  li {
    list-style: none;
    position: relative;
    padding: 0 0 0 20px;
  }
}

.flowerBullets {
  padding-left: 20%;
}

ul.burst {
  padding-left: 6rem;
}

ul.burst {
  li {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 15px;
      background: #e97b2d;
      width: 10px;
      height: 10px;
      text-align: center;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    &::after {
      content: "";
      position: absolute;
      top: 15px;
      left: 0;
      height: 10px;
      width: 10px;
      background: #e97b2d;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

ul.pac-man {
  li {
    &::before {
      content: "";
      position: absolute;
      left: 0px;
      top: 15px;
      width: 0px;
      height: 0px;
      border-right: 5px solid transparent;
      border-top: 5px solid #ff6600;
      border-left: 5px solid #ff6600;
      border-bottom: 5px solid #ff6600;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

#light {
  position: relative;
  top: 470px;
}


#lineh2 {
  position: absolute;
  left: 0;
  top: 30px;
  height: 10px;
  background: linear-gradient(180deg, #009139 0%, #0BACF5 100%);
  animation: lineH 5s 0.5s infinite linear;
}

#lineh4 {
  position: absolute;
  right: 0;
  top: 70px;
  height: 10px;
  background: linear-gradient(180deg, #009139 5%, #0BACF5 100%);
  animation: lineH 5s 1s infinite linear;
}


@keyframes lineH {
  0% {
    width: 0%;
  }

  100% {
    width: 95%;
    opacity: 0;
  }
}

.container.courseMaterial.pt-5 {
  padding-left: 55px;
  margin-top: 55px;

}

.container.courseMaterial.pt-5 .h2 {
  font-weight: 700;
  font-family:"Alata";
}

.materialTitle {
  font-size: 22px;
  font-weight: 700;
  font-family:"Alata";
}


@media (max-width: 550px) {
  ul.burst {
    padding-left: 1rem;
  }

}