.pythontitle {
    padding-left: 38px;
    font-weight: 700;
    margin-top: 30px;
    font-family:"Alata";

}



.paraPython {
    margin-left: 42px;
    margin-top: 20px;
    line-height: 30px;
    font-size: 16px;
    font-family:"Alata";
    font-weight: 500;
    text-align: justify;
}

.paraPython1 {
    margin-left: 42px;
    // margin-top: px;
    line-height: 30px;
    font-size: 16px;
    font-family:"Alata";
    font-weight: 500;
    text-align: justify;
}




$main_font: 'Roboto';
$main_color: #ff6600;

ul {
    font-size: 16px;
    line-height: 40px;
    margin: 6px 0 15px;
    padding: 0;
    font-family:"Alata";

    li {
        list-style: none;
        position: relative;
        padding: 0 0 0 20px;
    }
}

ul.diamond {
    li {
        &::before {
            content: "";
            margin-top: 10px;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 8px;
            height: 8px;
            border: 5px solid transparent;
            border-bottom-color: $main_color;
        }

        &::after {
            content: "";
            margin-top: 10px;
            position: absolute;
            left: 0px;
            top: 12px;
            width: 8px;
            height: 8px;
            border: 5px solid transparent;
            border-top-color: $main_color;
        }
    }
}



@media (max-width: 550px) {
    .pythontitle {
        padding-left: 0px;
        font-weight: 700;
        margin-top: 30px;
        font-family:"Alata";
    }

    .paraPython {
        margin-left: 0px;
    }

    .paraPython1 {
        margin-left: 0px;
    }
}