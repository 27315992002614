.branches {
  /* background-color: #013A6B;
  background-image: -webkit-linear-gradient(30deg, #bddcfb 50%, #61c8fb 50%); */
  background-color: rgb(237, 237, 253);
  height: auto;
}

.branchesMain {
  padding-left: 50px;
}
.custom_links_course{
  background-color: #fff;
  box-shadow: 2px 2px 4px 1pxrgb(0 0 0 / 10%);
  min-height: 30px;
  z-index: 86;
  padding: 0 5px;
  display: inline-flex;
  border: 1px solid #ddd;
  justify-content: center;
  align-items: center;
  margin: 0 5px 15px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;

}
.branchTab{
  color: #323232;
  text-decoration: none;
}
.branchMainCard{
  padding: 20px 20px 20px 20px;
  text-align: center;
  margin-top: 30px;
}
.branchName {
  color: #272525;
  text-align: center;
  font-family:"Alata";
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #00838d;
  border-radius: 0 4px 0 32px;
}

.card4 {
  .go-corner {
    background-color: #00838d;
    height: 100%;
    width: 16px;
    // padding-right: 5px;
    border-radius: 0;
    transform: skew(11deg);
    margin-right: -30px;
    align-items: start;
    background-image: linear-gradient(180deg, #009139 0%, #0BACF5 100%);
  }

  h3 {
    margin-top: 8px;
  }
}

.card4:hover {
  .go-corner {
    margin-right: -12px;
  }
}