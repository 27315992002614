.navbarstyle {
    background-color: #0066B0;
    color: white;
    font-size: 14px;
    font-weight: 500;
    height: 35px;
    cursor: pointer;
    display: flex;
    align-items: center !important;
    font-family:"Alata";

}
.navbarstyle1 {
    background-color: green;
    color: white;
    font-size: 14px;
    font-weight: 500;
    /* height: 35px; */
    cursor: pointer;
    display: flex;
    align-items: center !important;
    font-family:"Alata";

}
.contactNumber{
  padding: 20px;
  padding-left: 7%;
}
.download1{
    color:white;
    font-size: 14px;
    height: auto;
    width: auto;
    border-radius: 5px;
    border-color: red;
    background-color: red;
    font-weight: 500;
    font-family:"Alata";

}
@media (max-width: 550px) {
    .navbarstyle {
        font-size: 12px;
        height: 45px;
        display: flex;
        align-items: center !important;
        font-family:"Alata";;

    }

    .contact {
        margin-left: 10px;
    }
}

@media (max-width:856px) and (min-width:550px) {
    .navbarstyle {
        height: 60px;
        font-size: 12px;
        font-family:"Alata";

    }

    .contact {
        margin-left: 10px;
    }
}