
$font: "Alata";

.trainingCardMain {
 width: 100%;
 display: flex;
 justify-content: center;
 align-items: center;

 .trainingCard {
   height: 355px;
   background: #fff;
   border-radius: 4px;
   box-shadow: 0px 20px 30px -10px rgba(0, 0, 0, 0.1);
   max-width: 300px;
   min-width: 260px;
   overflow: hidden;
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-direction: column;
   position: relative;
   transition: all 0.4s ease;
   margin: 0 10px;

   &:before {
     height: 175px;
     width: calc(100% + 100px);
     content: "";
     position: absolute;
     background-image: linear-gradient(to top, #4481eb 0%, #04befe 100%);
     border-radius: 4px 4px 100% 100%;
     transition: all 0.4s ease-out;
     top: 0;
   }
  
  
   article {
     z-index: 1;
     display: flex;
     align-items: center;
     flex-direction: column;
     text-align: center;

     h2 {
       color: white;
       margin: 0;
       padding: 6px 20px 10px 20px;
       font-weight: 700;
       font-size: 26px;
       letter-spacing: 0.5px;
       margin-top: 10px;
       font-family:"Alata";
     }
     
     .title {
       color: white;
       padding: 10px 20px;
       letter-spacing: 0.8px;
       transition: all 0.4s ease;
     }

     .desc {
       font-size: 16px;
       text-align: center;
       line-height: 24px;
       color: black;
       height: 90px;
       transition: all 0.4s ease-out;
       font-family:"Alata";
     }

     .pic {
       width: 115px;
       height: 115px;
       overflow: hidden;
       border-radius: 100%;
       margin: 20px 0px;
       box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.3);
       transition: all 0.6s ease;

       img {
         width: 100%;
         -webkit-filter: grayscale(100%);
         filter: grayscale(100%);
       }
     }
   }
  

   &:hover {
     transform: translateY(-10px);
     box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.3);

     &:before {
       height: 100%;
       border-radius: 4px;
     }

     .desc {
       color: white;
     }
     
     .pic {
       box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.3);
       img {
         -webkit-filter: grayscale(0%);
         filter: grayscale(0%);
       }
     }
   }

   &.closed {
     min-width: 120px;
     max-width: 120px;
     display: flex;
     justify-content: center;
     align-items: center;
     transition: all 0.6s ease;
     cursor: pointer;

     .title,
     .desc,
     .actions,
     .close {
       display: none;
     }

     h2 {
       padding: 0;
       height: 100%;
       transform: rotate(-90deg);
       width: 440px;
       z-index: 2;
       transition: all 0.6s ease;
     }

     .pic {
       border-radius: 100%;
       height: 400px;
       width: 400px;
       position: absolute;
       top: -20px;
       margin: 0;
       box-shadow: 0;
       transition: all 0.6s ease;

       img {
         object-fit: cover;
         height: 100%;
         transform: translateY(20px);
       }

       &:before {
         content: "";
         position: absolute;
         width: 100%;
         height: 100%;
         background-color: black;
         opacity: 0.5;
         z-index: 1;
         transition: all 0.4s ease;
         transform: translateY(20px);
       }
     }

     &:before {
       height: 100%;
       border-radius: 4px;
     }
   }
 }
}
