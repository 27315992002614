$mobile-large : 512px;
$tablet : 768px;
$laptop : 992px;
$desktop : 1200px;
.scrollbars{
    max-height: 56vh;
    overflow-y: scroll;
}
.maincard1 {
    margin-top: 30px;
    width: 90%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.25) 0px -10px 15px, rgba(0, 0, 0, 0.12) 0px -20px 30px, rgba(0, 0, 0, 0.12) 0px 20px 50px, rgba(0, 0, 0, 0.17) 0px 1px 13px, rgba(0, 0, 0, 0.09) 0px 10px 5px;
}

.maincard2 {
    margin-top: 30px;
    width: 90%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.25) 0px -10px 15px, rgba(0, 0, 0, 0.12) 0px -20px 30px, rgba(0, 0, 0, 0.12) 0px 20px 50px, rgba(0, 0, 0, 0.17) 0px 1px 13px, rgba(0, 0, 0, 0.09) 0px 10px 5px;
}

.modules {
    font-family:"Alata";
}

.courseMain {
    margin-bottom: 100px;
}

.download_curriculum {
    color: white;
    font-size: 15px;
    height: 45px;
    width: 40%;
    border-radius: 5px;
    border-color: rgb(50, 50, 243);
    background-color: rgb(50, 50, 243);
    font-weight: 500;
    font-family:"Alata";
}

.request {
    color: white;
    font-size: 15px;
    height: auto;
    width: auto;
    border-radius: 5px;
    border-color: rgb(50, 50, 243);
    background-color: rgb(50, 50, 243);
    font-weight: 500;
    font-family:"Alata";
}

.do_you_like {
    font-size: 20px;
    font-weight: 700;
    font-family:"Alata";
}

.curriculum_btn {
    margin-top: 60px;
}

.m1 {
    height: 70px;
    cursor: pointer;
    font-weight: 500;
}

.mycoursecard {
    margin-left: 60px;
}

.course {
    background-color: rgb(233, 238, 245);
    height: 95vh;
    border-radius: 5px;
}

.intro {
    margin-top: 40px;
    position: relative;
    height: 65vh;
    text-align: center;
    text-shadow: 1px 1px;
    color: #fff;
    font-family:"Alata";

    @media (min-width: $mobile-large) {
        height: 10vh;
        width: 90%;
    }

    .image-overlay {
        display: block;
        position: absolute;
        bottom: 0;
        opacity: 0.3;
        height: 100%;
        top: 0;
    }

    //squares wrapper
    .squares-wrapper {
        background: #4e54c8;
        background: linear-gradient(to left, #3182d5, #4e54c8);
        width: 100%;
        height: 100%;
    }

    .squares {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin: 0;
        padding: 0;

        li {
            position: absolute;
            display: block;
            list-style: none;
            width: 20px;
            height: 20px;
            background: rgba(255, 255, 255, 0.2);
            animation: animate 25s linear infinite;
            bottom: -150px;
        }
    }
}

.quote {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 200%;
    overflow: hidden;
    width: 100vw;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
    z-index: 1;
   font-family:"Alata";

    h1 {
        margin-top: 0;
        font-size: 22px;
        font-weight: 700;
        text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
       font-family:"Alata";

        // >768
        @media (min-width: $tablet) {
            font-size: 20px;
            text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
        }
    }
}

.rightcard {
    background-color: rgb(202, 220, 245);
    height: 60vh;
}

.topics {
    padding-left: 40px;
    margin-top: 30px;
    font-size: 18px;
    font-weight: 700;
   font-family:"Alata";

}

.listed {
    // padding-left: 20px;
    // margin-top: 10px;
    // font-size: 18px;
    // line-height: 34px;
}

.diamondlisted {
    margin-top: 20px;
    list-style: none;
    // margin: 2em;
    line-height: 34px;
    margin-left: 44px;
    padding: 0;
   font-family:"Alata";

    .sublisted {
        // margin: 0;
        padding-left: 1.1em;
        position: relative;

        &:after {
            content: '';
            height: .4em;
            width: .4em;
            background: rgb(50, 50, 243);
            display: block;
            position: absolute;
            transform: rotate(45deg);
            top: 1em;
            left: 0;
        }

    }

}



@keyframes animate {

    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

@media (max-width: 550px) {

    .mycoursecard {
        margin-left: 0px;
    }

    .maincard2 {
        width: 100%;
    }

    .maincard1 {
        width: 100%;
    }

    .request_btn {
        display: flex;
        justify-content: center !important;
        margin-left: 30%;
        margin-top: 10px;
    }

    .intro {
        margin-top: 40px;
        position: relative;
        height: 30vh;
        text-align: center;
        text-shadow: 1px 1px;
        color: #fff;


        .image-overlay {
            display: block;
            position: absolute;
            bottom: 0;
            opacity: 0.3;
            top: 0;
        }

        //squares wrapper
        .squares-wrapper {
            background: #4e54c8;
            background: linear-gradient(to left, #3182d5, #4e54c8);
            width: 100%;
            height: 18vh;
        }
    }

    .quote {
        top: 35%
    }

    .rightcard {
        height: auto;
    }

    .download_curriculum {
        width: 60%;
    }

 
}