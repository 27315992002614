.accordionCard{
  display: flex;
  justify-content: center;
}
.collapseCard{
  width: 70%;
  background-color: white;
}

.paraAccordion{
  font-weight: 400;
  font-family:"Alata";

}
