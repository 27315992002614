.bannerimg {
    width: 100%;
    filter: blur(3px);
    -webkit-filter: blur(3px);
    position: absolute;

}

.titleBanner {
    color: white;
    /* font-size: 24px;
    font-weight: 700; */
    display: flex;
    justify-content: center !important;
    /* padding: 20px; */
    font-family: "Alata";

}

.bannersubpara {
    margin-top: 10px;
    font-family: "Alata";
}

.titleBannerHeading {
    margin-top: 30px;
    font-family: "Alata";

}

.bannerpara {
    color: white;
    font-weight: 400;
    font-size: 18px;
    display: flex;
    text-align: center;
    justify-content: center;
    line-height: 32px;
    font-family: "Alata";

}

.bannerStarted {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.started {
    font-family: "Alata";
    color: white;
    font-size: 16px;
    height: 50px;
    width: auto;
    border-radius: 5px;
    border-color: rgb(50, 50, 243);
    background-color: rgb(50, 50, 243);
    font-weight: 500;
    box-shadow: 0px 1px 2px rgb(8, 8, 8),
        1px 2px 4px rgb(8, 8, 8),
        2px 4px 4px rgb(8, 8, 8),
        2px 4px 16px rgb(8, 8, 8);
    font-family: "Alata";
}


@media (max-width: 576px) {
    .bannerimg {
        height: 100%;
    }

    .bannerpara {
        text-align: center;
        padding: 10px;
    }

    .started {
        width: 50%;
    }

    .titleBanner {
        padding: 10px;
        font-size: 16px;
        text-align: justify;
    }

    .bannerStarted {
        margin-top: 0px;
    }

    .bannersubpara {
        font-size: 15px;
        text-align: justify;
        margin-top: 30px;
    }

    .bannerContent {
        padding: 30px;
    }
}