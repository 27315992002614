.watchMore {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: 4%;
    font-family:"Alata";

}
.mainCarousel{
    margin-top: 30px;
}