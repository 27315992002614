.bannerCertificateimg {
    width: 100%;
    /* filter: blur(3px);
    -webkit-filter: blur(3px); */
    position: absolute;
    height: 80%;
}

.titleBanner {
    color: white;
    /* font-size: 24px;
    font-weight: 700; */
    display: flex;
    justify-content: center !important;
    /* padding: 20px; */
    font-family: "Alata";

}

.bannerContent {
    position: absolute;
}

.bannersubpara {
    margin-top: 10px;
    font-family: "Alata";
}

.titleBannerHeading {
    margin-top: 30px;
    font-family: "Alata";

}

.bannerpara {
    color: white;
    font-weight: 400;
    font-size: 18px;
    display: flex;
    text-align: center;
    justify-content: center;
    line-height: 32px;
    font-family: "Alata";

}

.bannerStarted {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.started {
    font-family: "Alata";
    color: white;
    font-size: 16px;
    height: 50px;
    width: auto;
    border-radius: 5px;
    border-color: rgb(50, 50, 243);
    background-color: rgb(50, 50, 243);
    font-weight: 500;
    box-shadow: 0px 1px 2px rgb(8, 8, 8),
        1px 2px 4px rgb(8, 8, 8),
        2px 4px 4px rgb(8, 8, 8),
        2px 4px 16px rgb(8, 8, 8);
    font-family: "Alata";
}

.courseTraining {
    margin-top: 80vh;
}

.bannerCourse {
    margin-top: 15vh;
}

.courseCerticatetitle {
    color: white;
    font-family: "Alata";
    font-size: 38px;
    font-weight: 600;
    margin-top: 18%;
    text-align: center;
    line-height: 60px;
}
.certificateImg{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width: 576px) {
    .bannerimg {
        height: 100%;
    }

    .bannerpara {
        text-align: center;
        padding: 10px;
    }

    .started {
        width: 50%;
    }

    .titleBanner {
        padding: 10px;
        font-size: 16px;
        text-align: justify;
    }

    .bannerStarted {
        margin-top: 0px;
    }

    .bannersubpara {
        font-size: 15px;
        text-align: justify;
        margin-top: 30px;
    }

    .bannerContent {
        padding: 30px;
    }

    .courseTraining {
        margin-top: 0;
    }

    .certificateImg {
        width: 100%;
        margin-top: 30px;
    }
    .courseCerticatetitle {
        color: black;
        font-family: "Alata";
        font-size: 26px;
        font-weight: 600;
        margin-top: 0%;
    }
    .bannerContent {
        position: relative;
    }
}