
.hovicon {
  font-size: 34px;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  text-decoration: none;
  z-index: 1;
  color: black;
  font-weight: 700;
  font-family:"Alata";
}


.hovicon:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/* Effect 4 */
.hovicon.effect-4 {
  width: 180px;
  height: 180px;
  box-shadow: 0 0 0 6px rgb(52, 56, 164);
}

.hovicon.effect-4:after {
  top: -5px;
  left: -5px;
  padding: 0;
  z-index: 10;
  border: 5px dashed #3137ad;
  
}

.hovicon.effect-4:hover {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  color: black;
  
}

.hovicon.effect-4:hover i {
  color: #fff;
}

.hovicon.effect-4.sub-b:hover:after {
  -webkit-animation: spinAround 9s linear infinite;
  -moz-animation: spinAround 9s linear infinite;
  animation: spinAround 9s linear infinite;
}

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes spinAround {
  from {
    -moz-transform: rotate(0deg)
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@keyframes spinAround {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg);
  }
}
.circletitle{
  font-size: 18px;
  font-family:"Alata";
}
.numbers{
  margin-top: 25px;
  font-family:"Alata";
}
.wholeCircle{
  display: flex;
  justify-content: space-evenly;
  margin-top: 55px;
}
.trainingheading2 {
  display: flex;
  justify-content: center;
  font-size: 28px;
  font-weight: 700;
  margin-top: 0px;
  font-family:"Alata";
}
@media (max-width: 550px) {
  // .trainingheading2 {
  //    margin-top: 40%;
  // }
  .wholeCircle{
   margin-left: 32%;
  }

}