@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins&display=swap');

.programHighlights {
  background-color: white;
  // height: 110vh;
}

.middlecircle {
  width: 5%;
  height: 5%;
}

.smallrect {
  width: 3%;
  height: 3%;
}

.programhighlightsTitile {
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-weight: 700;
  margin-top: 20px;
  font-family:"Alata";
  margin-top: 60px;
}

.holecardWithimg1 {
  display: flex;
  justify-content: end;
}

.holecardWithimg2 {
  display: flex;
  justify-content: start;
}

.container {
  // width: 1000px;

  .mycard {
    position: relative;
    margin-left: 9px;
    margin-right: 9px;
    height: 317px;

    .face {
      position: relative;
      width: 100%;
      max-width: 300px;
      height: 200px;
      transition: 0.5s;
      border-radius: 12px;

      &.face1 {
        position: relative;
        background-color: #013A6B;
        background-image: linear-gradient(180deg, #009139 0%, #0BACF5 100%);
        // background-image: -webkit-linear-gradient(-30deg, #0BACF5 50%, #009139 50%);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        transform: translateY(100px);
        text-shadow: 1px 1px 3px black, 0 0 0.4em blue, 0 0 0.3em blue;
      }
    }

    &:hover .face.face1 {
      transform: translateY(0);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-image: url("../Images/elearning.webp");
    }

    .face.face1 .content {
      // opacity: 0.8;
      transition: 0.5s;
    }

    &:hover .face.face1 .content {
      opacity: 1;
    }

    &:hover .face.face1 .mainheading1 {
      text-shadow: 1px 1px 3px black, 0 0 0.4em blue, 0 0 0.3em blue;
    }

    .para {
      font-size: 18px;
      font-family:"Alata";
      font-weight: 600;
    }

    .face {
      &.face1 .content {
        img {
          max-width: 60px;
        }

        .mainheading1 {
          margin: 10px 0 0;
          padding: 0;
          color: #fff;
          text-align: center;
          font-size: 1.3em;
          font-family:"Alata";
        }
      }

      &.face2 {
        position: relative;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-sizing: border-box;
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
        transform: translateY(-100px);
      }
    }

    &:hover .face.face2 {
      transform: translateY(0);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .face.face2 {
      p {
        margin: 0;
        padding: 0;
      }

    }
  }
}

.container {
  .mycard {
    .myface {
      position: relative;
      width: 100%;
      max-width: 300px;
      height: 200px;
      transition: 0.5s;
      border-radius: 12px;

      &.myface1 {
        position: relative;
        background-color: #013A6B;
        background-image: linear-gradient(180deg, #009139 0%, #0BACF5 100%);
        // background-image: linear-gradient(180deg, #009139 0%, #0BACF5 100%);
        // background: #333;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        transform: translateY(100px);
        text-shadow: 1px 1px 3px black, 0 0 0.4em blue, 0 0 0.3em blue;
      }
    }

    &:hover .myface.myface1 {
      transform: translateY(0);
      background-image: url("../Images/mylearnin.png");
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      // width: 100vh;
    }

    .myface.myface1 .content {
      transition: 0.5s;
    }

    &:hover .myface.myface1 .content {
      opacity: 1;
    }

    &:hover .myface.myface1 .mainheading1 {
      text-shadow: 1px 1px 3px black, 0 0 0.4em blue, 0 0 0.3em blue;
    }

    .myface {
      &.myface1 .content {
        img {
          max-width: 60px;
        }

        .mainheading1 {
          margin: 10px 0 0;
          padding: 0;
          color: #fff;
          text-align: center;
          font-size: 1.3em;
          font-family:"Alata";
        }
      }

      &.myface2 {
        position: relative;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-sizing: border-box;
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
        transform: translateY(-100px);
      }
    }

    &:hover .myface.myface2 {
      transform: translateY(0);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}


.container {
  .mycard {
    .my3face {
      position: relative;
      width: 100%;
      max-width: 300px;
      height: 200px;
      transition: 0.5s;
      border-radius: 12px;

      &.my3face1 {
        position: relative;
        // background: #333;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        transform: translateY(100px);
        background-color: #013A6B;
        background-image: linear-gradient(180deg, #009139 0%, #0BACF5 100%);
        text-shadow: 1px 1px 3px black, 0 0 0.4em blue, 0 0 0.3em blue;
      }
    }

    &:hover .my3face.my3face1 {
      transform: translateY(0);
      background-image: url("../Images/bestimg.jpg");
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      // width: 100vh;
    }

    .my3face.my3face1 .content {
      transition: 0.5s;
    }

    &:hover .my3face.my3face1 .content {
      opacity: 1;
    }

    &:hover .my3face.my3face1 .mainheading1 {
      text-shadow: 1px 1px 3px black, 0 0 0.4em blue, 0 0 0.3em blue;
    }

    .my3face {
      &.my3face1 .content {
        img {
          max-width: 60px;
        }

        .mainheading1 {
          margin: 10px 0 0;
          padding: 0;
          color: #fff;
          text-align: center;
          font-size: 1.3em;
          font-family:"Alata";
        }
      }

      &.my3face2 {
        position: relative;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-sizing: border-box;
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
        transform: translateY(-100px);
      }
    }

    &:hover .my3face.my3face2 {
      transform: translateY(0);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.container {
  .mycard {
    .my4face {
      position: relative;
      width: 100%;
      max-width: 300px;
      height: 200px;
      transition: 0.5s;
      border-radius: 12px;

      &.my4face1 {
        position: relative;
        // background: #333;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        transform: translateY(100px);
        background-color: #013A6B;
        background-image: linear-gradient(180deg, #009139 0%, #0BACF5 100%);
        text-shadow: 1px 1px 3px black, 0 0 0.4em blue, 0 0 0.3em blue;
      }
    }

    &:hover .my4face.my4face1 {
      transform: translateY(0);
      background-image: url("../Images/program.jpg");
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      // width: 100vh;
    }

    .my4face.my4face1 .content {
      transition: 0.5s;
    }

    &:hover .my4face.my4face1 .content {
      opacity: 1;
    }

    &:hover .my4face.my4face1 .mainheading1 {
      text-shadow: 1px 1px 3px black, 0 0 0.4em blue, 0 0 0.3em blue;
    }

    .my4face {
      &.my4face1 .content {
        img {
          max-width: 60px;
        }


        .mainheading1 {
          margin: 10px 0 0;
          padding: 0;
          color: #fff;
          text-align: center;
          font-size: 1.3em;
          font-family:"Alata";
        }
      }

      &.my4face2 {
        position: relative;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-sizing: border-box;
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
        transform: translateY(-100px);
      }
    }

    &:hover .my4face.my4face2 {
      transform: translateY(0);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.container {
  .mycard {
    .my5face {
      position: relative;
      width: 100%;
      max-width: 300px;
      height: 200px;
      transition: 0.5s;
      border-radius: 12px;

      &.my5face1 {
        position: relative;
        background-color: #013A6B;
        background-image: linear-gradient(180deg, #009139 0%, #0BACF5 100%);
        // background: #333;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        transform: translateY(100px);
        text-shadow: 1px 1px 3px black, 0 0 0.4em blue, 0 0 0.3em blue;
      }
    }

    &:hover .my5face.my5face1 {
      transform: translateY(0);
      background-image: url("../Images/learning.jpg");
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      // width: 100vh;
    }

    .my5face.my5face1 .content {
      transition: 0.5s;
    }

    &:hover .my5face.my5face1 .content {
      opacity: 1;
    }

    &:hover .my5face.my5face1 .mainheading1 {
      text-shadow: 1px 1px 3px black, 0 0 0.4em blue, 0 0 0.3em blue;
    }

    .my5face {
      &.my5face1 .content {
        img {
          max-width: 60px;
        }

        .mainheading1 {
          margin: 10px 0 0;
          padding: 0;
          color: #fff;
          text-align: center;
          font-size: 1.3em;
          font-family:"Alata";
        }
      }

      &.my5face2 {
        position: relative;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-sizing: border-box;
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
        transform: translateY(-100px);
      }
    }

    &:hover .my5face.my5face2 {
      transform: translateY(0);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}



@media (max-width: 550px) {
  .programhighlightsTitile {
    display: flex;
    justify-content: center !important;
    font-size: 24px;
    align-items: center;
    font-family:"Alata";
    margin-top: 10px;
  }

  .holecardWithimg1 {
    display: flex;
    justify-content: center !important;
  }

  .holecardWithimg2 {
    display: flex;
    justify-content: center !important;
  }

  .programHighlights {
    background-color: rgb(202, 220, 245);
    // height: 255vh;
  }

  .container {
    // width: 1000px;

    .mycard {
      position: relative;
      margin-left: 9px;
      margin-right: 9px;
      height: 290px;

      .face {
        position: relative;
        width: 100%;
        max-width: 300px;
        height: 160px;
        transition: 0.5s;
        border-radius: 12px;

        &.face1 {
          position: relative;
          background-image: url("../Images/elearning.webp");
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
          transform: translateY(100px);
          text-shadow: 1px 1px 3px black, 0 0 0.4em blue, 0 0 0.3em blue;
        }
      }

      &:hover .face.face1 {
        transform: translateY(0);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-color: #013A6B;
        background-image: linear-gradient(180deg, #009139 0%, #0BACF5 100%);
      }

      .face.face1 .content {
        // opacity: 0.8;
        transition: 0.5s;
      }

      &:hover .face.face1 .content {
        opacity: 1;
      }

      &:hover .face.face1 .mainheading1 {
        text-shadow: 1px 1px 3px black, 0 0 0.4em blue, 0 0 0.3em blue;
      }

      .para {
        font-size: 18px;
        font-weight: 600;
      }

      .face {
        &.face1 .content {
          img {
            max-width: 60px;
          }

          .mainheading1 {
            margin: 10px 0 0;
            padding: 0;
            color: #fff;
            text-align: center;
            font-size: 1.3em;
            font-family:"Alata";
          }
        }

        &.face2 {
          position: relative;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;
          box-sizing: border-box;
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
          transform: translateY(-100px);
          margin-top: 10px;
        }
      }

      &:hover .face.face2 {
        transform: translateY(0);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      .face.face2 {
        p {
          margin: 0;
          padding: 0;
        }

      }
    }
  }

  .container {
    .mycard {
      .myface {
        position: relative;
        width: 100%;
        max-width: 300px;
        height: 160px;
        transition: 0.5s;
        border-radius: 12px;

        &.myface1 {
          position: relative;
          background-color: #013A6B;
          background-image: linear-gradient(180deg, #009139 0%, #0BACF5 100%);
          // background: #333;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
          transform: translateY(100px);
          text-shadow: 1px 1px 3px black, 0 0 0.4em blue, 0 0 0.3em blue;
        }
      }

      &:hover .myface.myface1 {
        transform: translateY(0);
        background-image: url("../Images/mylearnin.png");
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        // width: 100vh;
      }

      .myface.myface1 .content {
        transition: 0.5s;
      }

      &:hover .myface.myface1 .content {
        opacity: 1;
      }

      &:hover .myface.myface1 .mainheading1 {
        text-shadow: 1px 1px 3px black, 0 0 0.4em blue, 0 0 0.3em blue;
      }

      .myface {
        &.myface1 .content {
          img {
            max-width: 60px;
          }

          .mainheading1 {
            margin: 10px 0 0;
            padding: 0;
            color: #fff;
            text-align: center;
            font-size: 1.3em;

          }
        }

        &.myface2 {
          position: relative;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;
          box-sizing: border-box;
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
          transform: translateY(-100px);
          margin-top: 10px;
        }
      }

      &:hover .myface.myface2 {
        transform: translateY(0);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }


  .container {
    .mycard {
      .my3face {
        position: relative;
        width: 100%;
        max-width: 300px;
        height: 160px;
        transition: 0.5s;
        border-radius: 12px;

        &.my3face1 {
          position: relative;
          // background: #333;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
          transform: translateY(100px);
          background-color: #013A6B;
          background-image: linear-gradient(180deg, #009139 0%, #0BACF5 100%);
          text-shadow: 1px 1px 3px black, 0 0 0.4em blue, 0 0 0.3em blue;
        }
      }

      &:hover .my3face.my3face1 {
        transform: translateY(0);
        background-image: url("../Images/bestimg.jpg");
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        // width: 100vh;
      }

      .my3face.my3face1 .content {
        transition: 0.5s;
      }

      &:hover .my3face.my3face1 .content {
        opacity: 1;
      }

      &:hover .my3face.my3face1 .mainheading1 {
        text-shadow: 1px 1px 3px black, 0 0 0.4em blue, 0 0 0.3em blue;
      }

      .my3face {
        &.my3face1 .content {
          img {
            max-width: 60px;
          }

          .mainheading1 {
            margin: 10px 0 0;
            padding: 0;
            color: #fff;
            text-align: center;
            font-size: 1.3em;

          }
        }

        &.my3face2 {
          position: relative;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;
          box-sizing: border-box;
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
          transform: translateY(-100px);
          margin-top: 10px;
        }
      }

      &:hover .my3face.my3face2 {
        transform: translateY(0);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .container {
    .mycard {
      .my4face {
        position: relative;
        width: 100%;
        max-width: 300px;
        height: 160px;
        transition: 0.5s;
        border-radius: 12px;

        &.my4face1 {
          position: relative;
          // background: #333;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
          transform: translateY(100px);
          background-color: #013A6B;
          background-image: linear-gradient(180deg, #009139 0%, #0BACF5 100%);
          text-shadow: 1px 1px 3px black, 0 0 0.4em blue, 0 0 0.3em blue;
        }
      }

      &:hover .my4face.my4face1 {
        transform: translateY(0);
        background-image: url("../Images/program.jpg");
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        // width: 100vh;
      }

      .my4face.my4face1 .content {
        transition: 0.5s;
      }

      &:hover .my4face.my4face1 .content {
        opacity: 1;
      }

      &:hover .my4face.my4face1 .mainheading1 {
        text-shadow: 1px 1px 3px black, 0 0 0.4em blue, 0 0 0.3em blue;
      }

      .my4face {
        &.my4face1 .content {
          img {
            max-width: 60px;
          }


          .mainheading1 {
            margin: 10px 0 0;
            padding: 0;
            color: #fff;
            text-align: center;
            font-size: 1.3em;
          }
        }

        &.my4face2 {
          position: relative;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;
          box-sizing: border-box;
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
          transform: translateY(-100px);
          margin-top: 10px;
        }
      }

      &:hover .my4face.my4face2 {
        transform: translateY(0);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .container {
    .mycard {
      .my5face {
        position: relative;
        width: 100%;
        max-width: 300px;
        height: 160px;
        transition: 0.5s;
        border-radius: 12px;

        &.my5face1 {
          position: relative;
          background-color: #013A6B;
          background-image: linear-gradient(180deg, #009139 0%, #0BACF5 100%);
          // background: #333;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
          transform: translateY(100px);
          text-shadow: 1px 1px 3px black, 0 0 0.4em blue, 0 0 0.3em blue;
        }
      }

      &:hover .my5face.my5face1 {
        transform: translateY(0);
        background-image: url("../Images/learning.jpg");
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        // width: 100vh;
      }

      .my5face.my5face1 .content {
        transition: 0.5s;
      }

      &:hover .my5face.my5face1 .content {
        opacity: 1;
      }

      &:hover .my5face.my5face1 .mainheading1 {
        text-shadow: 1px 1px 3px black, 0 0 0.4em blue, 0 0 0.3em blue;
      }

      .my5face {
        &.my5face1 .content {
          img {
            max-width: 60px;
          }

          .mainheading1 {
            margin: 10px 0 0;
            padding: 0;
            color: #fff;
            text-align: center;
            font-size: 1.3em;
          }
        }

        &.my5face2 {
          position: relative;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;
          box-sizing: border-box;
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
          transform: translateY(-100px);
          margin-top: 10px;
        }
      }

      &:hover .my5face.my5face2 {
        transform: translateY(0);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}